import React, { useState } from 'react';
import { AppBar, Avatar, Menu } from '@material-ui/core';
import { Subscribe, useSubscribe } from '@react-rxjs/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { PaymentsIcon } from '../../icons/PaymentsIcon';
import { useConnectionStatus } from '../../shared/websocket/connectionStatus';
import { MarketSelector, marketSelectorState$ } from './MarketSelector/MarketSelector';
import { getSecuritiesWhenAuthenticated$ } from '../../shared/services/securitiesService';
import { MemberPortal } from './MemberPortal';
import { LoginButton } from './LoginButton';
import { useMobileCheck } from '../../shared/hooks/useMobileCheck';

import {
  AvatarWrapper,
  NavBarLeftWrapper,
  NavBarRightWrapper,
  NavBarWrapper,
  NavBarSecondRowWrapper,
  MenuConnectionStatusWrapper,
  AvatarStatusOverlay,
  MenuNameAndEmail,
  UserMenuItemStyled,
  MenuNameAndEmailWrapper,
  UserMenuListItemTextStyled,
  UserMenuListItemIconStyled,
  NavBarLoggedOutRightWrapper,
} from './styled';
import { TwentyFourHourContainer as TwentyFourHour } from './twentyFourHour/TwentyFourHourContainer';
import { getBalancesWhenAccounts$ } from '../balances/services/balanceService';
import { NotificationDropdown } from '../notifications/NotificationDropdown';
import { ConnectionStatusWidget } from '../../shared/components/ConnectionStatusWidget';
import { useUserInfo } from './services/userInfoService';
import {
  AuthStatus,
  useAuthStatus,
} from '../../shared/services/authStatusService';
import { EnvBanner } from '../envBanner/EnvBanner';
import { Config } from '../../config/config';
import { setShowUserPrefs } from './services/userPrefsService';
import { AccountManagementViewType, showAccountManagementView } from '../accountManagement';
import { useHasPermission, ApiPermission } from '../../shared/services/userPermissionsService';
import { FeatureToggle } from '../../shared/featureToggle/featureToggle';
import { Feature } from '../../shared/featureToggle/constants';
import { CboeDigitalLogo } from '../../shared/assets/CboeDigitalLogo';

interface Props {
  logUserOut: () => void;
}

export const NavBar: React.FunctionComponent<Props> = (props) => {
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
  const isMobile = useMobileCheck();
  const connectionStatus = useConnectionStatus();
  const authStatus = useAuthStatus();
  const userInfo = useUserInfo();
  const hasPermission = useHasPermission();

  useSubscribe(getSecuritiesWhenAuthenticated$);
  useSubscribe(getBalancesWhenAccounts$);

  return (
    <div>
      <AppBar position="static">
        {Config.BANNER_TEXT !== 'none' ? (
          <EnvBanner text={Config.BANNER_TEXT} />
        ) : null}
        <NavBarWrapper>
          <NavBarLeftWrapper>
            <CboeDigitalLogo />
            {!isMobile && (
              <>
                <Subscribe
                  source$={marketSelectorState$}
                  fallback={<>loading...</>}
                >
                  <MarketSelector />
                </Subscribe>
                <TwentyFourHour />
              </>
            )}
          </NavBarLeftWrapper>
          {authStatus === AuthStatus.AUTHENTICATED ? (
            <NavBarRightWrapper>
              <MemberPortal />
              <NotificationDropdown />
              <AvatarWrapper onClick={(e) => setAnchorEl(e.target)}>
                <Avatar
                  id="avatar"
                  aria-controls="simple-menu"
                  aria-haspopup={true}
                  src={userInfo ? userInfo.picture : ''}
                />
                <ExpandMoreIcon />
                {isMobile && (
                  <AvatarStatusOverlay connectionStatus={connectionStatus} />
                )}
              </AvatarWrapper>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl as Element}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{ disablePadding: true }}
              >
                {userInfo && (
                  <MenuNameAndEmailWrapper>
                    <MenuNameAndEmail>{userInfo.email}</MenuNameAndEmail>
                  </MenuNameAndEmailWrapper>
                )}
                <UserMenuItemStyled
                  onClick={() => {
                    setShowUserPrefs(true);
                    setAnchorEl(null);
                  }}
                >
                  <UserMenuListItemIconStyled>
                    <PersonIcon />
                  </UserMenuListItemIconStyled>
                  <UserMenuListItemTextStyled primary="User Preferences" />
                </UserMenuItemStyled>
                {hasPermission(ApiPermission.SETTLEMENT_SERVICE)
                  && Config.SETTLEMENT_SERVICE_ENABLED === 'true' && (
                    <FeatureToggle feature={Feature.SETTLEMENT_INSTRUCTIONS}>
                      <UserMenuItemStyled
                        onClick={() => {
                          showAccountManagementView(AccountManagementViewType.SETTLEMENTS);
                          setAnchorEl(null);
                        }}
                      >
                        <UserMenuListItemIconStyled>
                          <PaymentsIcon />
                        </UserMenuListItemIconStyled>
                        <UserMenuListItemTextStyled primary="Settlement Service" />
                      </UserMenuItemStyled>
                    </FeatureToggle>
                )}
                <UserMenuItemStyled
                  onClick={() => {
                    props.logUserOut();
                    setAnchorEl(null);
                  }}
                >
                  <UserMenuListItemIconStyled>
                    <ExitToAppIcon />
                  </UserMenuListItemIconStyled>
                  <UserMenuListItemTextStyled primary="Sign out" />
                </UserMenuItemStyled>
                {isMobile && (
                  <MenuConnectionStatusWrapper>
                    <ConnectionStatusWidget />
                  </MenuConnectionStatusWrapper>
                )}
              </Menu>
            </NavBarRightWrapper>
          ) : (
            <NavBarLoggedOutRightWrapper>
              <LoginButton />
            </NavBarLoggedOutRightWrapper>
          )}
        </NavBarWrapper>
        {isMobile && (
          <NavBarSecondRowWrapper>
            <Subscribe
              source$={marketSelectorState$}
              fallback={<>loading...</>}
            >
              <MarketSelector />
            </Subscribe>
            <TwentyFourHour />
          </NavBarSecondRowWrapper>
        )}
      </AppBar>
    </div>
  );
};
